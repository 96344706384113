/* Define CSS variables for easier theme management */
:root {
    --font-primary: 'Helvetica', sans-serif;
    --color-background: #d0d0d0;
    --color-text: #333;
    --color-highlight: #ff6347;
    --color-item-background: #fff3e0;
    --color-button-background: #ffcc5c;
    --color-button-hover: #ffeb3b;
    --color-add-item-button: #7bc043;
    --color-add-item-button-hover: #82ca9d;
    --color-most-voted-background: #e8f5e9;
    --color-most-voted-border: #4caf50;
    --color-most-voted-text: #2e7d32;
    --color-most-voted-button: #81c784;
    --color-most-voted-button-hover: #66bb6a;
  }
  
  body {
    font-family: var(--font-primary);
    background-color: var(--color-background);
    color: var(--color-text);
    margin: 0;
    padding: 20px;
  }
  
  header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  h1 {
    color: var(--color-highlight);
    font-size: 2.5em;
  }
  
  .main {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .item {
    background-color: var(--color-item-background);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 7px;
  }
  
  .item span {
    font-size: 1.2em;
  }
  
  .actions button {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: var(--color-button-background);
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
  }
  
  .actions button:hover {
    background-color: var(--color-button-hover);
  }
  
  .addItemForm {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .inputField {
    flex-grow: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .addItemButton {
    background-color: var(--color-add-item-button);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .addItemButton:hover {
    background-color: var(--color-add-item-button-hover);
  }
  
  .mostVoted {
    background-color: var(--color-most-voted-background);
    border: 2px solid var(--color-most-voted-border);
    color: var(--color-most-voted-text);
  }
  
  .mostVoted .actions button {
    background-color: var(--color-most-voted-button);
  }
  
  .mostVoted .actions button:hover {
    background-color: var(--color-most-voted-button-hover);
  }
  
  @media (max-width: 768px) {
    body {
      padding: 10px;
    }
  
    h1 {
      font-size: 2em;
    }
  
    .item {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 7px;
    }
  
    .item span, .actions button {
      margin-bottom: 5px;
    }
  
    .actions {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 3px;
    }
  
    .actions button {
      width: 48%; /* Make buttons take up almost half the width, with a little space between */
    }
  
    .addItemForm {
      flex-direction: column;
    }
  
    .inputField,
    .addItemButton {
      width: 100%;
      margin: 5px 0; /* Add a little margin to the top and bottom for spacing */
    }
  }